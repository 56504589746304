import { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createAttributeThunk, createItemAttributeThunk, deleteAttributeThunk, deleteItemAttributeThunk, getAllAttributesThunk, updateAttributeThunk, updateItemAttributeThunk } from "../../../store/attributes";
import { Modal, ModalBody } from "react-bootstrap";
import swal from "sweetalert";

function AllAttributes() {
  const dispatch = useDispatch();
  const attributes = useSelector((state) => state.attributes.attributes);
  const [name, setName] = useState("");
  const [require_number, setRequireNumber] = useState(0);
  const [attributeOptions, setAttributeOptions] = useState({ 
    name: '',
    chinese_name: '',
    price_modifier: 0,
  })
  const [editAttributeOptions, setEditAttributeOptions] = useState({
    name: '',
    chinese_name: '',
    price_modifier: 0,
  })
  


  // State to manage the visibility of the modal and the selected attribute
  const [showModal, setShowModal] = useState(false);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [showOptionModal, setShowOptionModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);



  useEffect(() => {
    dispatch(getAllAttributesThunk());
  }, [dispatch]);


  // Function to handle the click event and show the modal with attribute options
  const handleAttributeClick = (attribute) => {
    setSelectedAttribute(attribute);
    setShowModal(true);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setShowOptionModal(true);
    setEditAttributeOptions(option);
  }

  //handle add new attribute options
    const handleAddOption = async (e,option) => {
        e.preventDefault();
        try{
       let newOption = dispatch(createItemAttributeThunk(selectedAttribute.id, attributeOptions));
       if(newOption){ 
        swal("Success", "New Option Added", "success");
        setAttributeOptions({
            name: '',
            chinese_name: '',
            price_modifier: 0,
        })
        setShowModal(false);
       }else { 
        swal("Error", "Failed to add new option", "error");
       }
        }catch(err){
          swal("Error", "Failed to add new option", "error");
        }
    }

    const handleDeleteOption = async () => {
        try{
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this option!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then(async (willDelete) => {
            if (willDelete) {
                dispatch(deleteItemAttributeThunk(selectedAttribute.id, selectedOption.id))
              swal("Poof! Your option has been deleted!", {
                icon: "success",
              });
              setShowModal(false);
              setShowOptionModal(false);
            } else {
              swal("Your option is safe!");
            }
          });
        }catch(err){
          swal("Error", "Failed to delete option", "error");
        }
    }

    const handleEditOption = async () => {
      try { 
        let updatedOption = dispatch(updateItemAttributeThunk(selectedAttribute.id, selectedOption.id, editAttributeOptions));
        if(updatedOption){ 
          swal("Success", "Option Updated", "success");
          setShowModal(false);
          setShowOptionModal(false);
          setAttributeOptions
          ({
            name: '',
            chinese_name: '',
            price_modifier: 0,
          })
        }else { 
          swal("Error", "Failed to update option", "error");
        }
      }
      catch(err){
        swal("Error", "Failed to update option", "error");
      }
    }

    const handleAddAttribute = async (e) => {
      e.preventDefault();
      try{
        let newAttribute = dispatch(createAttributeThunk({ name }));
        if(newAttribute){ 
          swal("Success", "New Attribute Added", "success");
          setName('');
        }else { 
          swal("Error", "Failed to add new attribute", "error");
        }
      }catch(err){
        swal("Error", "Failed to add new attribute", "error");
      }
    }

    const handleDelete = async (e,id) => {
      e.stopPropagation();
      try {
        swal({
          title: "Are you sure?",
          text: "Once deleted you will not be able to recover this attribute!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then(async (willDelete) => {
          if (willDelete) {
            dispatch(deleteAttributeThunk(id))
            swal("Poof! Your attribute has been deleted!", {
              icon: "success",
            });
          } else {
            swal("Your attribute is safe!");
          }
        });
      }
      catch(err){
        swal("Error", "Failed to delete attribute", "error");
      }
    }
    const handleEditAttribute = (e, attribute) => {
      e.stopPropagation();
      setShowEditModal(true);
      setSelectedAttribute(attribute);
    };

    const handleSubmitEditAttribute = async (e) => {
      e.preventDefault();
      try {
        let updatedAttribute = dispatch(updateAttributeThunk(name, require_number, selectedAttribute.id));
        if(updatedAttribute){ 
          swal("Success", "Attribute Updated", "success");
          setShowEditModal(false);
          setName('')
          setRequireNumber(0)
        }else { 
          swal("Error", "Failed to update attribute", "error");
        }
      }
      catch(err){
        swal("Error", "Failed to update attribute", "error");
      }
    }
    

  return (
    <Fragment>
 <div className="row">
  <form className="col-12" onSubmit={handleAddAttribute}>
    <div className="row">
      <div className="col-8">
        <input
          type="text"
          className="form-control"
          placeholder="Add New Attribute"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div className="col-4">
        <button type="submit" className="btn btn-primary mb-4">Add Attribute</button>
      </div>
    </div>
  </form>
</div>


      


      <div className="row">
  


        {attributes?.map((attribute) => (
          <div
            key={attribute.id}
            className="col-xl-3 col-lg-3 col-md-4 col-sm-6 p-2"
            style={{ cursor: "pointer" }}
            onClick={() => handleAttributeClick(attribute)}
          >
            <div className="card">
              <div className="card-body">
                <div className="new-arrival-product">
                  <div className="new-arrival-content text-center mt-3"  >
                    <h4>
                      <span className="text-black">{attribute?.name}</span>
                    </h4>
              <div className="row">
                <div className="col-md-6">
              <button className="btn btn-primary" onClick={(e) => handleEditAttribute(e,attribute)}>Edit</button>
              </div>
              <div className="col-md-6">
              <button className="btn btn-danger" onClick={(e) => handleDelete(e,attribute.id)}>Delete</button>
              </div>
              </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Modal to display attribute options */}
      <Modal centered show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{selectedAttribute?.name} Options</Modal.Title>
        </Modal.Header>
        <ModalBody>
        {/*add new attribute option*/}
        <div className="row">
          <form onSubmit={handleAddOption} className="col-12">
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label htmlFor="option">Add Enligsh</label>
                  <input
                    type="text"
                    className="form-control"
                    id="option"
                    placeholder="Spicy, Mild, etc."
                    required
                    value={attributeOptions.name}
                    onChange={(e) => setAttributeOptions({...attributeOptions, name: e.target.value})}
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label htmlFor="chineseName">Add Chinese</label>
                  <input
                    type="text"
                    className="form-control"
                    id="chineseName"
                    placeholder="辣, 温和, etc."
                    required
                    value={attributeOptions.chinese_name}
                    onChange={(e) => setAttributeOptions({...attributeOptions, chinese_name: e.target.value})}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="priceModifier">Price</label>
                  <input
                    type="number"
                    className="form-control"
                    id="priceModifier"
                    placeholder="0.00"
                    required
                    value={attributeOptions.price_modifier}
                    onChange={(e) => setAttributeOptions({...attributeOptions, price_modifier: e.target.value})}
                  />
                </div>
              </div>
            </div>

            <button type="submit" className="btn btn-primary mb-4">Add Option</button>
          </form>
        </div>




        {/*display attribute options*/}
        <div className="row">
  {selectedAttribute?.Item_Attribute_Options?.map((option) => (
    <div
      key={option.id}
      className="col-xl-4 col-lg-4 col-md-6 col-sm-6"
      style={{ cursor: "pointer" }}
    >
      <div className="card">
        <div className="card-body">
          <div className="new-arrival-product">
            <div className="new-arrival-content text-center mt-3">
              <h4
                onClick={() => handleOptionClick(option)}
                className="custom-hover-option"
                style={{ transition: 'color 0.3s', cursor: 'pointer' }}
              >
                {option.name}
              </h4>
              <p>{option.chinese_name}</p>
              <p className="text-danger">${option.price_modifier}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  ))}
</div>


    {/* Modal to display attribute options */}
    <Modal show={showOptionModal} centered onHide={() => setShowOptionModal(false)} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{selectedOption?.name}</Modal.Title>
      </Modal.Header>
      <ModalBody>
        <div className="row">
          <form className="col-12">
            <div className="row">
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="option">English Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="option"
                    value={editAttributeOptions?.name}
                    onChange={(e) => setEditAttributeOptions({...editAttributeOptions, name: e.target.value})}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="chineseName">Chinese Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="chineseName"
                    value={editAttributeOptions?.chinese_name}
                    onChange={(e) => setEditAttributeOptions({...editAttributeOptions, chinese_name: e.target.value})}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="priceModifier">Price Modifier</label>
                  <input
                    type="text"
                    className="form-control"
                    id="priceModifier"
                    value={editAttributeOptions?.price_modifier}
                    onChange={(e) => setEditAttributeOptions({...editAttributeOptions, price_modifier: e.target.value})}
                  />
                </div>
              </div>
            </div>
          </form>
            <button type="submit" className="btn btn-primary mb-4" onClick={handleEditOption}>Save Option</button>
            <button  className="btn btn-danger mb-4 mr-2" onClick={handleDeleteOption}>Delete Option</button>
        </div>


        </ModalBody>
        </Modal>

                 
        </ModalBody>
      </Modal>


      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Attribute</Modal.Title>
        </Modal.Header>
        <ModalBody>
          <div>
            <label>Name</label>
            <input 
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <label>Require Number(If empty: any amount can be selected)</label>
            <input 
              type="number"
              className="form-control"
              value={selectedAttribute?.requ}
              onChange={(e) => setRequireNumber(e.target.value)}
            />
            <button className="btn btn-primary" onClick={(e) => handleSubmitEditAttribute(e)}>Save</button>
          </div>
        </ModalBody>
      </Modal>


    </Fragment>
  );
}

export default AllAttributes;
