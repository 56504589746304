import { useDispatch, useSelector } from "react-redux";
import { createRewardThunk, deleteRewardThunk, getRewardsThunk } from "../../../store/rewards";
import { useEffect, useState } from "react";
import { getItemsThunk } from "../../../store/items";

export default function Rewards(){ 
    const dispatch = useDispatch();
    const rewards = useSelector(state => state.rewards.rewards);
    const items = useSelector((state) => state.items.items);
    const [addRewardModal, setAddRewardModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const [reward_input, setRewardInput] = useState({
        item_id: '',
        need_amount: '',
        quantity: ''
    });

    useEffect(() => {
        dispatch(getRewardsThunk());
        dispatch(getItemsThunk());
    }, [dispatch]);

    const filteredItems = items?.filter(item => !rewards?.some(reward => reward.item_id === item.id) && item.name.toLowerCase().includes(searchQuery.toLowerCase()));

    return (
        <div className="container mt-4">
            <hr />
            <button className="btn btn-primary mb-3" onClick={() => setAddRewardModal(true)}>Add Reward</button>

            <div className="d-flex flex-wrap">
                {rewards?.map(reward => (
                    <div key={reward.id} className="card mb-3 flex-grow-1" style={{ flexBasis: 'calc(33.333% - 1rem)', margin: '0.5rem' }}>
                        <div className="card-body">
                            <h5 className="card-title">{reward?.Item?.name}</h5>
                            <p className="card-text">Points Need: {reward.need_amount}</p>
                            <button className="btn btn-danger" onClick={() => dispatch(deleteRewardThunk(reward.id))}>Delete Reward</button>
                        </div>
                    </div>
                ))}
            </div>

            {addRewardModal && (
                <div className="modal show d-block center" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add Reward</h5>
                                <button type="button" className="btn-close" onClick={() => setAddRewardModal(false)}></button>
                            </div>
                            <div className="modal-body">
                                <input 
                                    className="form-control mb-3" 
                                    type="text" 
                                    value={searchQuery} 
                                    onChange={(e) => setSearchQuery(e.target.value)} 
                                    placeholder="Search Item"
                                />
                                <select className="form-select mb-3" value={reward_input.item_id} onChange={(e) => setRewardInput({...reward_input, item_id: e.target.value})}>
                                    <option value=''>Select Item</option>
                                    {filteredItems?.map(item => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))}
                                </select>
                                <input className="form-control mb-3" type='number' value={reward_input.need_amount} onChange={(e) => setRewardInput({...reward_input, need_amount: e.target.value})} placeholder='Need Amount'/>
                                <input className="form-control mb-3" type='number' value={reward_input.quantity} onChange={(e) => setRewardInput({...reward_input, quantity: e.target.value})} placeholder='Quantity'/>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-secondary" onClick={() => setAddRewardModal(false)}>Close</button>
                                <button className="btn btn-primary" onClick={() => {
                                    dispatch(createRewardThunk(reward_input));
                                    setAddRewardModal(false);
                                }}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
