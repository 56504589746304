import { getToken } from "./utlits";

const GET_REWARDS = 'rewards/GET_REWARDS';
const CREATE_REWARD = 'rewards/CREATE_REWARD';
const DELETE_REWARD = 'rewards/DELETE_REWARD';

const getRewards = (rewards) => ({
    type: GET_REWARDS,
    rewards
});

const createReward = (reward) => ({
    type: CREATE_REWARD,
    reward
});

const deleteReward = (rewardId) => ({
    type: DELETE_REWARD,
    rewardId
});

export const getRewardsThunk = () => async (dispatch) => {
    const token = getToken();
    if(!token) return null;

    const response = await fetch(`/api/rewards/admin`, {
        headers: {
            'Content-Type': 'application/json',
            credentials: 'include',
            'authorization': `Bearer ${token}`
        }
    });

    if (response.ok) {
        const rewards = await response.json();
        dispatch(getRewards(rewards));
    }
}

export const createRewardThunk = (reward) => async (dispatch) => {
    const token = getToken();
    if(!token) return null;

    const response = await fetch(`/api/rewards/admin`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            credentials: 'include',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify(reward)
    });

    if (response.ok) {
        const reward = await response.json();
        dispatch(createReward(reward));
    }
}

export const deleteRewardThunk = (rewardId) => async (dispatch) => {
    const token = getToken();
    if(!token) return null;

    const response = await fetch(`/api/rewards/restaurant/${rewardId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            credentials: 'include',
            'authorization': `Bearer ${token}`
        }
    });

    if (response.ok) {
        dispatch(deleteReward(rewardId));
    }
}


const initialState = {};

const rewardsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_REWARDS:
            return { ...state, rewards: action.rewards };
        case CREATE_REWARD:
            return { ...state, rewards: [...state.rewards, action.reward] };
        case DELETE_REWARD:
            return { ...state, rewards: state.rewards.filter(reward => reward.id !== action.rewardId) };
        default:
            return state;
    }
}

export default rewardsReducer;
