import { getToken } from "./utlits";

const GET_CUSTOMERS = 'customers/GET_CUSTOMERS';
const UPDATE_POINTS = 'customers/UPDATE_POINTS';

const getCustomers = (customers) => ({
    type: GET_CUSTOMERS,
    customers
});

export const updatePoints = (points) => ({
    type: UPDATE_POINTS,
    points
});


export const getCustomerThunk = (page) => async (dispatch) => {
    const token = getToken();
    if (!token) return null;

    const response = await fetch(`/api/rewards/all/user?page=${page}`, { 
        headers: {
            'authorization': `Bearer ${token}`
        }
    
    });

    if (response.ok) {
        const customers = await response.json();
        dispatch(getCustomers(customers));
    }
}

export const updatePointsThunk = (id, points) => async (dispatch) => {
    const token = getToken();
    if (!token) return null;

    const response = await fetch(`/api/rewards/user/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ points })
    });

    if (response.ok) {
        const customer = await response.json();
        dispatch(updatePoints(customer));
    }
}

const initialState = { customers: [] };

export default function customersReducer(state = initialState, action) {
    switch (action.type) {
        case GET_CUSTOMERS:
           return { 
                ...state,
                customers: action.customers
           }
        case UPDATE_POINTS:
            return {
                ...state,
                customers: state.customers.map((customer) => {
                    if (customer.id === action.points.id) {
                        return action.points;
                    }
                    return customer;
                })
            }
        default:
            return state;
    }
}
