import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Pagination, Table } from "react-bootstrap";
import { getCustomerThunk, updatePointsThunk } from "../../../store/customers";
import swall from 'sweetalert2';
function AllCustomers() {
    const dispatch = useDispatch();
    const customers = useSelector((state) => state.customers.customers);
    const [currentPage, setCurrentPage] = useState(1);
    const [points , setPoints] = useState(0);
    const [modal, setModal] = useState(false);
    
  
    useEffect(() => {
      dispatch(getCustomerThunk(currentPage));
    }, [dispatch,  currentPage]);
  
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
      window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top of the page
    };

    function formatTimestamp(timestamp) {
        const date = new Date(timestamp);
        const options = {
            year: "numeric",
            month: "long",
            day: "numeric",

          
        };
        return date.toLocaleDateString("en-US", options);
    }

    const handleUpdatePoints = (id) => {
        dispatch(updatePointsThunk(id, points));
        setPoints(0);

        swall.fire({
            title: 'Points Updated',
            text: 'Points have been updated successfully',
            icon: 'success',
            confirmButtonText: 'Ok'
        })
        setModal(false);

    }
      
    return (
      <div className="container">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">All Customers</h5>
            <Table responsive striped bpersoned hover>
              <thead>
                <tr>
                  <th>Id#</th>
                  <th> Name</th>
                  <th> Phone</th>
                  <th>Points</th>
                  <th>Since</th>
                  <th>Update Points</th>
                </tr>
              </thead>
              <tbody>
                {customers?.map((customer) => (
                  <tr key={customer.id}>
                    <td>{customer?.id}</td>
                    <td>{customer?.name} </td>
                    <td>{customer?.phone}</td>
                    <td>{customer?.points}</td>
                    <td>{formatTimestamp(customer?.createdAt)}</td>
                    <td>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => setModal(true)}
                      >
                        Update Points
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="d-flex justify-content-center">
              <Pagination>
                <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                <Pagination.Item>{currentPage}</Pagination.Item>
                <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
              </Pagination>
            </div>
          </div>
        </div>

        <Modal show={modal} onHide={() => setModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Update Points</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="points">Points</label>
              <input
                type="number"
                className="form-control"
                id="points"
                value={points}
                onChange={(e) => setPoints(e.target.value)}
                
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary"
              onClick={() => setModal(false)}
            >
              Close
            </button>
            <button
              className="btn btn-primary"
              onClick={() => handleUpdatePoints(customers[0]?.id)}
            >
              Update
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  
  export default AllCustomers;
  
